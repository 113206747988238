import * as _react2 from "react";

var _react = "default" in _react2 ? _react2.default : _react2;

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
!function () {
  "use strict";

  var e = {
    n: function (t) {
      var r = t && t.__esModule ? function () {
        return t.default;
      } : function () {
        return t;
      };
      return e.d(r, {
        a: r
      }), r;
    },
    d: function (t, r) {
      for (var n in r) e.o(r, n) && !e.o(t, n) && Object.defineProperty(t, n, {
        enumerable: !0,
        get: r[n]
      });
    },
    o: function (e, t) {
      return Object.prototype.hasOwnProperty.call(e, t);
    },
    r: function (e) {
      "undefined" != typeof Symbol && Symbol.toStringTag && Object.defineProperty(e, Symbol.toStringTag, {
        value: "Module"
      }), Object.defineProperty(e, "__esModule", {
        value: !0
      });
    }
  },
      t = {};
  e.r(t), e.d(t, {
    default: function () {
      return c;
    }
  });
  var r = _react,
      n = e.n(r);

  function o() {
    return o = Object.assign ? Object.assign.bind() : function (e) {
      for (var t = 1; t < arguments.length; t++) {
        var r = arguments[t];

        for (var n in r) Object.prototype.hasOwnProperty.call(r, n) && (e[n] = r[n]);
      }

      return e;
    }, o.apply(this || _global, arguments);
  }

  function a(e, t) {
    return function (e) {
      if (Array.isArray(e)) return e;
    }(e) || function (e, t) {
      var r = null == e ? null : "undefined" != typeof Symbol && e[Symbol.iterator] || e["@@iterator"];

      if (null != r) {
        var n,
            o,
            a = [],
            l = !0,
            c = !1;

        try {
          for (r = r.call(e); !(l = (n = r.next()).done) && (a.push(n.value), !t || a.length !== t); l = !0);
        } catch (e) {
          c = !0, o = e;
        } finally {
          try {
            l || null == r.return || r.return();
          } finally {
            if (c) throw o;
          }
        }

        return a;
      }
    }(e, t) || function (e, t) {
      if (e) {
        if ("string" == typeof e) return l(e, t);
        var r = Object.prototype.toString.call(e).slice(8, -1);
        return "Object" === r && e.constructor && (r = e.constructor.name), "Map" === r || "Set" === r ? Array.from(e) : "Arguments" === r || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(r) ? l(e, t) : void 0;
      }
    }(e, t) || function () {
      throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
    }();
  }

  function l(e, t) {
    (null == t || t > e.length) && (t = e.length);

    for (var r = 0, n = new Array(t); r < t; r++) n[r] = e[r];

    return n;
  }

  var c = function (e) {
    var t = e.text,
        l = void 0 === t ? "speech synthesis react" : t,
        c = a((0, r.useState)(!1), 2),
        i = (c[0], c[1]),
        s = a((0, r.useState)({
      from: 0,
      to: 0
    }), 2),
        u = s[0],
        d = s[1],
        f = window.speechSynthesis,
        p = new SpeechSynthesisUtterance(l);
    p.addEventListener("start", function () {
      return i(!0);
    }), p.addEventListener("end", function () {
      return i(!1);
    }), p.addEventListener("boundary", function (e) {
      var t = e.charIndex,
          r = e.charLength;
      d({
        from: t,
        to: t + r
      });
    });

    var m = a((0, r.useState)(!1), 2),
        b = m[0],
        y = m[1],
        h = a((0, r.useState)(!0), 2),
        v = h[0],
        g = h[1],
        w = function (e) {
      var t = function (e, t, r) {
        return [e.slice(0, t), e.slice(t, r), e.slice(r)];
      }(e.text, e.from, e.to),
          r = a(t, 3),
          o = r[0],
          l = r[1],
          c = r[2];

      return n().createElement("p", null, o, n().createElement("span", {
        style: {
          backgroundColor: "#00ff51ab"
        }
      }, l), c);
    };

    return n().createElement("div", {
      className: "py-16 relative"
    }, n().createElement("div", {
      className: "mx-auto  w-[100%]  grid grid-cols-12 "
    }, n().createElement("div", {
      className: "col-span-8 my-6 w-[90%]"
    }, n().createElement("div", {
      className: "my-4 "
    }, b && n().createElement("button", {
      style: {
        display: "flex",
        width: "80px",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "transparent",
        border: "none",
        color: "green",
        fontWeight: "bold",
        cursor: "pointer"
      },
      onClick: function () {
        f.pause(), g(!0), y(!1);
      },
      className: "border-2 bg-black border-black hover:bg-slate-700 rounded-full w-28 h-10 flex items-center  space-x-1 py-3 pl-3 pr-2 text-white"
    }, " ", n().createElement("span", {
      className: "font-semibold "
    }, "Paush"), n().createElement("svg", {
      xmlns: "http://www.w3.org/2000/svg",
      fill: "none",
      viewBox: "0 0 24 24",
      "stroke-width": "1.5",
      stroke: "currentColor",
      class: "w-6 h-6"
    }, n().createElement("path", {
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      d: "M14.25 9v6m-4.5 0V9M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
    }))), v && n().createElement("button", {
      style: {
        display: "flex",
        width: "80px",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "transparent",
        border: "none",
        color: "green",
        fontWeight: "bold",
        cursor: "pointer"
      },
      onClick: function () {
        y(!0), g(!1), f ? (f.speak(p), f.resume()) : console.error("no tts");
      },
      className: "border-2 border-black bg-black hover:bg-slate-700 rounded-full w-28 h-10 flex items-center  px-3 space-x-2 py-3 text-white"
    }, " ", n().createElement("span", {
      className: "font-semibold "
    }, "Listen"), n().createElement("svg", {
      xmlns: "http://www.w3.org/2000/svg",
      fill: "none",
      viewBox: "0 0 24 24",
      "stroke-width": "1.5",
      stroke: "currentColor",
      class: "w-6 h-6"
    }, n().createElement("path", {
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      d: "M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
    }), n().createElement("path", {
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      d: "M15.91 11.672a.375.375 0 010 .656l-5.603 3.113a.375.375 0 01-.557-.328V8.887c0-.286.307-.466.557-.327l5.603 3.112z"
    })))), n().createElement(w, o({
      text: l
    }, u)))));
  };

  exports = t;
}();
export default exports;